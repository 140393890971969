import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default class ToastComponent extends Component {

    componentDidMount() {
        let self = this;
        if (navigator.onLine === false) self.emitOffline();
        window.addEventListener('online', () => {
            self.emitOnline();
        });
        window.addEventListener('offline', () => {
            self.emitOffline();
        });
    }
    emitOffline() {
        toast.dismiss("online-toast");
        ToastComponent.warning('You are currently offline.', { autoClose: false, toastId: "offline-toast", });
    }

    public static warning(WaringMessage = '', options = {}) {
        if (WaringMessage)
            toast.warn(WaringMessage, {
                autoClose: 2500,
                closeOnClick: false,
                className: "font-weight-bold text-white text-center",
                draggable: false,
                position: "bottom-center",
                ...options
            });
    }
    public static success(SuccessMessage = '', options = {}) {
        if (SuccessMessage)
            toast.success(SuccessMessage, {
                autoClose: 2500,
                closeOnClick: false,
                className: "font-weight-bold text-white text-center",
                draggable: false,
                toastId: "offline-toast",
                position: "bottom-center",
                ...options
            });
    }
    public static error(errorMessage = '', options = {}) {
        if (errorMessage)
            toast.error(errorMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                ...options
            });
    }

    public static dismiss(id = '') {
        if (id === '') toast.dismiss();
        else toast.dismiss(id);
    }

    emitOnline() {
        toast.dismiss("offline-toast");
        ToastComponent.success('You are back online.', { toastId: "online-toast" });
    }
    static toast(msg = "", options: any = {
        autoClose: 2500,
        className: "font-weight-bold text-white text-center",
        position: "bottom-center"
    }) {
        toast(msg, { ...options })
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
            </React.Fragment>
        )
    }
}

