export default class Validations {
  public static validateText = (input, max: number = 10) => {
    let text = input + "";
    return text
      .replace(/[0-9.,`~>!@#$%=^&*()_+{}":?</*\-+';\\\]\[]/gim, "")
      .slice(0, max);
  };
  public static validateTextWithNumber(input, max: number = 30): string {
    let text = input + "";
    let match = text.match(new RegExp("\\w{0," + (max - 1) + "}", "g"));
    if (match && match[0]) return match[0] + "";
    return "";
  }
  public static validateInteger = (input, max = 10) => {
    let text = input + "";
    let match = text.match(
      new RegExp("([1-9])([0-9]?){1," + (max - 1) + "}", "g")
    );
    if (match && match[0]) return match[0];
    return 0;
  };
  public static validateFloat = (
    input,
    maxDigits: number = 10,
    maxDecimal: number = 2
  ) => {
    let text = input + "";
    if (text == "0" || text == "0." || text == "") return text;
    let match = text.match(
      new RegExp(
        "(0?\\.\\d{1," +
          maxDecimal +
          "})|(([1-9](\\d{0," +
          (maxDigits - 1) +
          "})?)(\\.\\d{0," +
          maxDecimal +
          "})?)",
        "g"
      )
    );
    if (match && match[0]) return match[0];
    return 0;
  };
  public static isValidEmail(emailId: string = ""): boolean {
    return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,8})$/.test(
      emailId
    );
  }
  public static isValidDate(
    input: Date | number,
    maxDate?: Date | number,
    minDate?: Date | number
  ) {
    let date = new Date(input);
    if (date.toString() === "Invalid Date") return false;
    if (
      minDate &&
      this.convertTimeStampToDate(input) < this.convertTimeStampToDate(minDate)
    ) {
      return false;
    }
    if (
      maxDate &&
      this.convertTimeStampToDate(input) > this.convertTimeStampToDate(maxDate)
    ) {
      return false;
    }
    return true;
  }
  public static convertTimeStampToDate(input) {
    var d = new Date(input);
    let year = d.getFullYear();
    let month = d.getMonth();
    let day = d.getDate();
    var d2 = new Date(year, month, day, 0, 0, 0).getTime();
    return d2;
  }
  public static validateAddress(value: string): any {
    // return /^[^<>]*$/.test(value)
    return /^[a-zA-Z0-9 .,'#"@:%&/-]*$/.test(value);
  }
}
